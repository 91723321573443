$colors: (
	"grey": #c0c0c0
);
$fonts: (
	"logo": #{'\'Libre Caslon Display\', serif'},
	"headers": #{'\'Libre Caslon Display\', serif'},
	"body": #{'\'EB Garamond\'', serif}
);
$breakpoints: (
	"tablet": 800px,
	"desktop": 1200px
);
