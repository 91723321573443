header {
	@extend %clear;
	left: 0;
	padding: 3vmin;
	position: fixed;
	top: 0;
	transition: all 0.4s ease;
	width: 100%;
	z-index: 1000;
	& > * {
		transition: all 0.4s ease;
	}
	& > a {
		display: block;
		font-family: map-get($fonts, "logo");
		font-size: 1.5em;
		font-weight: normal;
		text-align: center;
		white-space: nowrap;
	}
	nav {
		display: table;
		font-size: 1em;
		margin: 0 auto;
		a {
			margin: 0 0.5em;
		}
	}
	@media screen and (min-width: map-get($breakpoints, "tablet")) {
		padding: 0;
		& > a {
			float: left;
			font-size: 2em;
			margin: 5vmin;
		}
		nav {
			float: right;
			display: flex;
			margin: 6.5vmin;
		}
	}
}

body {
	&.scrolled {
		header {
			background: rgba(#000, 0.9);
			@media screen and (min-width: map-get($breakpoints, "tablet")) {
				& > a {
					font-size: 2.6rem;
					margin: 3.2vmin 3vmin 0 3vmin;
				}
				nav {
					margin: 4.5vmin;
				}
			}
		}
	}
}
