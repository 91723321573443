.image{
	cursor: pointer;
	overflow: hidden;
	perspective: 600px;
	position: relative;
	& > div { // .card
		height: 100%;
		position: relative;
		transition: transform 1s ease;
		transform-origin: center right;
		transform-style: preserve-3d;
		width: 100%;
		& > div { // .card-face
			backface-visibility: hidden;
			height: 100%;
			width: 100%;
			&:first-child{
				// position: absolute;

			}
			&:last-child{
				background: rgba(#000, 0.9);
				position: absolute;
				top: 0;
				transform: rotateY(180deg);
			}
		}
	}
	img{
		display: block;
		position: absolute;
		transform: none;
		transition: transform 0.4s cubic-bezier(.25,.1,.25,1);
	}
	h2{
		color: #fff;
		font-size: 24px;
		font-weight: normal;
		margin: 0;
		padding: 0.25em 0.5em;
		position: absolute;
		text-align: center;
		top: 50%;
		// transition: transform 0.4s ease;
		transform: translate3d(0, -50%, 0);
		width: 100%;
	}
	&:not(.flip):not(.flipping):hover{
		img{
			transform: translateZ(0px) scale3d(1.25, 1.25, 1);
		}
		// h2{
		// 	transform: translate3d(0, 0, 0);
		// }
	}
	&.flip{
		& > div {
			transform: translateX(-100%) rotateY(-180deg);
			// & > div {
			// 	position: relative;
			// }
		}
	}
	&.loading{
		background: rgba(#f0f0f0, 0.1);
	}
	&.loaded{
		img{
			animation: fadeIn 2s ease;
		}
	}
	.spinner {
		opacity: 0.51;
		position: absolute;
	}
}
