.App {
    &.drum-lessons {
        header, footer {
            display: none;
        }
        video {
            // min-height: 20vh;
            max-width: 100%;
            width: 100%;
        }
        section {
            padding-top: 5vh;
            padding-bottom: 5vh;
        }
        h4 {
            font-weight: normal;
        }
    }
}