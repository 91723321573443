@keyframes arrowRight {
	0% {
		transform: translate3d(0.5em, 0, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}
@keyframes links-0 {
	0% {
		transform-origin: right;
		transform: scale3d(1, 1, 1);
	}
	100% {
		transform-origin: right;
		transform: scale3d(0, 1, 1);
	}
}
@keyframes links-1 {
	0% {
		transform-origin: left;
		transform: scale3d(0, 1, 1);
	}
	100% {
		transform-origin: left;
		transform: scale3d(1, 1, 1);
	}
}
@keyframes sectionAppear {
	0% {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes sectionAppearImg {
	0% {
		opacity: 0;
		// transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		// transform: translate3d(0, 0, 0);
	}
}
@keyframes galleryHeadingAppear {
	0% {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
