.App {
	&.people, &.places, &.other, &.cartoons, &.fantasyandscifi {
		main {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				&.gallery { // Gallery images
						li {
							animation: sectionAppearImg 1s ease forwards;
							border: 1px solid rgba(#fff, 0.1);
							opacity: 0;
							position: relative;
							transition: all 1s ease;
							&:hover {
								box-shadow: 0 0 20px rgba(#fff, 0.35);
								border-color: #fff;
							}
							@for $i from 1 through 20 {
								&:nth-of-type(#{$i}) {
									animation-delay: #{$i * 0.2}s;
								}
							}
							img {
								height: 100%;
								width: 100%;
								object-fit: cover;
								transition: all 1s ease;
							}
						}
						@media screen and (min-width: map-get($breakpoints, "tablet")) {
							display: grid;
							grid-gap: 1rem;
							grid-template-columns: repeat(12, 1fr);
							padding: 16vmin 10vmin 5vmin 10vmin;
							width: calc(100% - 12em);
						}
				}
			}
			& > h1 {
				animation: galleryHeadingAppear 1s ease forwards;
				bottom: 0px;
				color: rgba(#fff, 0.7);
				font-size: 2em;
				left: 0;
				opacity: 0;
				position: fixed;
				text-align: center;
				width: 100%;
				z-index: 100;
				@media screen and (min-width: map-get($breakpoints, "tablet")) {
					font-size: 3em;
					left: auto;
					right: 5vmin;
					width: auto;
				}
			}
			div { // Selected image container
				&.selectedImage {
					animation: sectionAppearImg 1s ease forwards;
					background: rgba(#000, 0.95);
					height: 100%;
					opacity: 0;
					position: fixed;
					text-align: center;
					top: 0;
					width: 100%;
					z-index: 1000;
					div {
						align-items: center;
						display: flex;
						flex-direction: column;
						height: 100%;
						opacity: 0;
						padding-top: 3em;
						position: absolute;
						justify-content: center;
						transition: opacity 1s ease;
						width: 100%;
						&.selected {
							opacity: 1;
						}
						img {
							border: 1px solid rgba(#fff, 0.15);
							box-shadow: 0 0 30px rgba(#000, 1), 0 0 20vmin rgba(#ffffff, 0.2);
							margin: 0 auto -5vmin auto;
							max-height: 600px;
							max-width: 75%;
						}
						h3{
							font-size: 1.6em;
							margin: 1em 1em 0 1em;
							@media screen and (min-width: map-get($breakpoints, "tablet")) {
								font-size: 2.4em;
								margin-top: 1.5em;
							}
						}
						p {
							font-size: 0.8em;
							opacity: 0.5;
							margin: 0 1em;
							@media screen and (min-width: map-get($breakpoints, "tablet")) {
								font-size: 1em;
							}
						}
					}
					ul {
						height: 100%;
						left: 0;
						position: fixed;
						top: 0;
						width: 100%;
						li {
							cursor: pointer;
							user-select: none;
							padding: 1em;
							position: absolute;
							text-transform: uppercase;
							transition: all 0.4s ease;
							@media screen and (min-width: map-get($breakpoints, "tablet")) {
								opacity: 0.25;
							}
							&:hover {
								opacity: 1;
							}
							&:after, &:before {
								margin: 0 0.5em;
							}
							&.close {
								width: 100%;
								@media screen and (min-width: map-get($breakpoints, "tablet")) {
									padding: 2em;
									&:before {
										content: 'Close';
									}
								}
							}
							&.prev, &.next {
								height: 100%;
								@media screen and (min-width: map-get($breakpoints, "tablet")) {
									line-height: 100vmin;
									width: 25%;
								}
								i {
									display: inline-block;
									font-size: 1em;
									padding: 0 0.25em;
								}
							}
							&.prev {
								left: 0;
								@media screen and (min-width: map-get($breakpoints, "tablet")) {
									&:after {
										content: 'Prev';
									}
								}
							}
							&.next {
								right: 0;
								@media screen and (min-width: map-get($breakpoints, "tablet")) {
									&:before {
										content: 'Next';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
