footer {
	opacity: 0.75;
	padding: 5vmin 1em 1em 1em;
	p {
		margin: 0;
	}
	img {
		width: 100px;
	}
}
