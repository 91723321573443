@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 3px;
    height: 16px;
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(#fff, 0.2);
    border-radius: 3px;
	 &:active {
		 background: rgba(#fff, 0.25);
	 }
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
}

// no scroll bar
@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    width: 0;
  }
}
