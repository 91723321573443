.App {
	&.courses {
		@extend .home;
		main {
			& > h1 {
				animation: galleryHeadingAppear 1s ease forwards;
				bottom: 0px;
				color: rgba(#fff, 0.7);
				font-size: 2em;
				left: 0;
				opacity: 0;
				position: fixed;
				text-align: center;
				width: 100%;
				z-index: 100;
				@media screen and (min-width: map-get($breakpoints, "tablet")) {
					font-size: 3em;
					left: auto;
					right: 5vmin;
					width: auto;
				}
			}
			section {
				div {
					& > button, & > a {
						@extend a;
						background: rgba(#fff, 0);
						border: none;
						display: block;
						float: right;
						font-size: 1em;
						outline: none;
						padding: 0.5em 1em;
						text-transform: uppercase;
						i {
							animation: arrowRight 1s cubic-bezier(.31,.17,.83,.7) infinite alternate;
							padding-left: 0.5em;
						}
					}
				}
			}
		}
		iframe {
			border: none;
			display: block;
			height: 75vmin;
			margin: 0 auto;
			max-width: 700px;
			min-height: 500px;
			width: 90%;
		}
	}
}
