@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,500;1,500&family=Libre+Caslon+Display&display=swap&family=IM+Fell+DW+Pica+SC&display=swap');
@import './../../node_modules/font-awesome/scss/font-awesome';
@import 'settings';
@import 'mixins';
@import 'animations';
@import 'header';
@import 'footer';
@import 'image';
@import 'sections';
@import 'pages/home';
@import 'pages/gallery';
@import 'pages/news';
@import 'pages/courses';
@import 'pages/lost';
@import 'pages/drumlessons';

%clear {
	&:after{
		clear: both;
		content: '';
		display: table;
	}
}

*{
	box-sizing: border-box;
}
body {
	@include custom-scroll-bar();
	background: #000;
	color: #fff;
	font-family: map-get($fonts, "body");
	font-size: 1.5em;
}
a {
	color: inherit;
	position: relative;
	text-decoration: none;
	&:after {
		border-top: 1.4px solid lighten(map-get($colors, "grey"), 20%);
		bottom: -5px;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		width: 0%;
	}
	&:hover, &.active {
		&:after {
			animation: links-0 0.4s cubic-bezier(.1,.85,.63,.97) forwards, links-1 0.4s cubic-bezier(.43,.22,.82,.72) forwards 0.4s;
			width: 100%;
		}
	}
}
h1, h2, h3 {
	display: block;
	font-family: map-get($fonts, "headers");
	font-weight: normal;
	line-height: 1.25em;
	margin: 0 0 0.5em 0;
}
h1 {
	font-size: 3em;
}
h2 {
	font-size: 2em;
}
h3 {
	font-size: 1.6em;
}
blockquote {
	line-height: 2em;
	margin: 1em;
	width: 75%;
	&:before, &:after {
		opacity: 0.1;
		font-size: 5em;
		line-height: 1em;
	}
	&:before {
		content: '“';
		float: left;
		margin: -0.1em 0.1em 0 0;
	}
	// &:after {
	// 	content: '”';
	// 	float: right;
	// 	margin: -0.1em 0 0 3em;
	// }
}
section {
	@extend %clear;
	& > div {
		@extend %clear;
	}
}

@media screen and (min-width: map-get($breakpoints, "tablet")) {
	main {
		section {
			&:first-of-type {
				padding-top: 15vmin;
			}
		}
	}
	.appear {
		opacity: 0;
		img {
			opacity: 0;
		}
		&.visible {
			animation: sectionAppear 1s ease forwards;
			img {
				animation: sectionAppearImg 2s cubic-bezier(.4,0,.2,1) forwards 0.2s;
			}
		}
	}
}
