.App {
	&.news {
		section {
			h3 {
				color: darken(#fff, 61%);
				span {
					padding: 0.1em;
				}
			}
			p {
				color: darken(#fff, 30%);
				font-style: normal;
				margin-bottom: 1em;
				img {
					margin: 0;
					transform: none!important;
				}
				a {
					opacity: 0.5;
					transition: opacity 0.4s ease;
					&:hover{
						opacity: 0.9;
					}
				}
			}
			img {
				margin-bottom: 5vmin;
			}
			&:nth-child(4n + 2) {
				@extend .breakout;
				padding-top: 10vmin;
			}
			&:nth-child(4n + 4) {
				@extend .boxout;
				& > div {
					margin: 0 auto;
				}
			}
			@media screen and (min-width: map-get($breakpoints, "tablet")) {
				padding-bottom: 10vmin;
				h3 {
					border: 0px solid rgba(#fff, 0.1);
					border-left-width: 1px;
					display: inline-block;
					float: right;
					line-height: 1.5em;
					margin: 0 0 0 1em;
					padding: 1em 0.75em;
					text-align: center;
					span {
						display: block;
						&:nth-child(1) { // Day
							font-size: 1.6em;
							line-height: 1.25em;
						}
						&:nth-child(2) { // Month
							text-transform: uppercase;
						}
						&:nth-child(3) { // Year
						}
					}
				}
				img {
					margin-bottom: 0;
				}
				p {
					&:first-of-type{
						padding-top: 5vmin;
					}
					img {
						// float: inherit!important;
						width: 20%!important;
					}
				}
				& > div {
					margin: 0 auto;
					max-width: 75%;
				}
				&:nth-child(2n + 2) {
					text-align: right;
					h3 {
						border-left-width: 0px;
						border-right-width: 1px;
						float: left;
						margin: 0 1em 0 0;
					}
					img {
						float: right;
						margin-left: 5vmin;
						margin-right: 0;
						transform: rotateY(-39deg) scale3d(0.75, 0.75, 1);
					}
				}
				&:first-of-type {
					padding-top: 20vmin;
				}
				&:not(:first-of-type) {
					padding-top: 10vmin;
				}
				&.boxout {
					margin-top: -10vmin;
					img {
						max-width: 100%;
					}
					p {
						&:first-of-type{
							padding-top: 0;
						}
					}
				}
				&.picture {
					a {
						color: #aaa;
						display: inline;
						float: none;
						margin: 0;
					}
					img {
						max-height: 40vmin;
						// transform: none;
						width: auto;
					}
				}
			}
		}
	}
}
