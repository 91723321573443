.App.home {
	main {
		big {
			@extend h3;
		}
		p {
			line-height: 2em;
		}
		section {
			&:first-of-type {
				&:after {
					background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
					bottom: 0;
					content: '';
					height: 101%;
					left: 0;
					position: absolute;
					width: 100%;
				}
				background-size: cover;
				padding: 43vmin 10vmin 30vmin 10vmin;
				text-align: center;
				h1 {
					font-size: 3em;
					font-weight: normal;
					position: relative;
					text-align: center;
					z-index: 10;
				}
				p {
					font-size: 1em;
					font-style: italic;
					margin: 1em auto;
					position: relative;
					z-index: 10;
					&:nth-of-type(1) {
						font-family: map-get($fonts, "headers");
						font-size: 1.6em;
					}
				}
				@media screen and (min-width: map-get($breakpoints, "tablet")) {
					padding: 43vmin 25vmin 30vmin 25vmin;
				}
			}
			&.boxout {
				display: table;
				margin: auto;
				min-width: 75%;
				& > div {
					overflow: hidden;
				}
				img {
					position: absolute;
					right: 0px;
					transform: rotate(0deg);
					height: 148%;
					top: -65px;
					z-index: -1;
				}
			}
		}
		// animation: sectionAppearImg 2s cubic-bezier(.4,0,.2,1) forwards 0.2s;
		@media screen and (min-width: map-get($breakpoints, "tablet")) {
			section {
				& > div {
					// display: table;
					margin: 0 auto;
					max-width: 75%;
				}
				h2 {
					font-size: 2.4em;
					& + p {
						font-size: 1em;
						font-style: italic;
					}
				}
			}
		}
	}
}
