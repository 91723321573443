section {
	padding: 20vmin 10vmin;
	perspective: 70px;
	&.boxout {
		padding-bottom: 5vmin;
		& > div {
			background: #111;
			padding: 6vmin;
			min-width: 61%;
		}
	}
	&.breakout {
		background: #111;
	}
	&.picture {
		div {
			perspective: 100vmin;
			@media screen and (min-width: map-get($breakpoints, "tablet")) {
				max-width: 85%;
				width: 85%;
			}
			// h3 {
			// 	margin: 2em 0 0 0;
			// }
			p {
				color: darken(#fff, 61%);
				font-style: italic;
				margin: 0;
			}
			a {
				@media screen and (min-width: map-get($breakpoints, "desktop")) {
					display: block;
					float: right;
					margin-top: 15vmin;
				}
			}
			img {
				transition: transform 0.4s ease;
				width: 100%;
				@media screen and (min-width: map-get($breakpoints, "tablet")) {
					display: block;
					float: left;
					margin-right: 5vmin;
					transform: rotateY(39deg) scale3d(0.75, 0.75, 1);
					width: 50%;
					&:hover {
						transform: rotateY(0deg)!important;
					}
					// & + div {
					// 	float: left;
					// 	font-size: 1em;
					// 	font-style: italic;
					// 	margin: 2em;
					// }
				}
			}
		}
	}
	&.videos {
		& > div {
			perspective: 600px;
		}
		.video-container {
			display: block;
			float: right;
			transition: transform 0.4s ease;
			@media screen and (min-width: map-get($breakpoints, "tablet")) {
				transform: rotateY(-23deg) scale3d(0.75, 0.75, 1);
				width: 100vmin;
				&:hover {
					transform: rotateY(0deg) scale3d(1, 1, 1);
				}
			}
			iframe {
				display: block;
				height: 56vmin;
				margin: 0 auto;
				width: 100%;
				@media screen and (min-width: map-get($breakpoints, "tablet")) {
				}
			}
		}
		a {
			opacity: 0.5;
			transition: opacity 0.4s ease;
			&:hover {
				opacity: 0.75;
			}
		}
	}
	&.grid {
		@media screen and (min-width: map-get($breakpoints, "tablet")) {
			div {
				align-items: center;
				display: flex;
				margin: 0 auto;
				max-width: 50%;
				h3 {
					font-size: 2em;
					font-weight: normal;
					width: 25%;
				}
				p {
					width: 75%;
				}
			}
		}
	}
}
